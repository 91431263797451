import TableWrapper from "components/table-wrapper/TableWrapper";
import "styles/pages/savings/savings.scss";
import SavingsTable from "./SavingsTable";
import SavingsTableHeaderButtons from "./SavingsTableHeaderButtons";
import {useEffect, useState, useContext} from "react";
import {handleOpen, LinkModal} from "blockmate-react-link";
import LinkService from "../../services/LinkService";
import {useConfig} from "../../components/config/config";
import {UserContext} from "../../contexts/UserContext/UserContext";
import {useSearchParams} from "react-router-dom";
import handleApiError from "../../services/ErrorHandleUtil";

const Savings = () => {
  const [tableData, setTableData] = useState([]);
  const [ongoingWithdrawalInfo, setOngoingWithdrawalInfo] = useState(null);

  const { linkToken, userID } = useContext(UserContext);

  const { getConfig } = useConfig();
  const [searchParams] = useSearchParams();

  const supportedChain = "BTC";

  const getTableData = () => {
    LinkService.getConnectedPaymentAccounts(getConfig("link_url"), linkToken, supportedChain).then(data => {
      setTableData(data);
    }).catch((reason) => {
      handleApiError("getting connected payment accounts", reason);
    });
  };

  useEffect(() => {
    if (tableData.length === 0 && linkToken !== null) {
      getTableData();
    }
  }, [linkToken]);

  // Check for ongoing offramp process using url parameters from offramp
  useEffect(() => {
    const externalTransactionId = searchParams.get("externalTransactionId");
    const baseCurrencyCode = searchParams.get("baseCurrencyCode");
    const baseCurrencyAmount = searchParams.get("baseCurrencyAmount");
    const depositWalletAddress = searchParams.get("depositWalletAddress");
    const ongoingWithdrawalAccountId = searchParams.get("accountId");
    if (externalTransactionId && baseCurrencyCode && baseCurrencyAmount && depositWalletAddress) {
      setOngoingWithdrawalInfo({
        externalTransactionId,
        baseCurrencyCode,
        baseCurrencyAmount,
        depositWalletAddress
      });
      handleOpen("withdrawAssets", ongoingWithdrawalAccountId);
    }
  }, [tableData]);

  return (
    <>
      <LinkModal
        url={getConfig("link_url")}
        jwt={linkToken}
        cleanupActions={{
          success: () => window.location.reload() // Do not eta-reduce this
        }}
        additionalUrlParams={ongoingWithdrawalInfo}
      />
      <TableWrapper
        icon={<img className="container-icon" alt="" src="../images/assets.svg"/>}
        title='Crypto savings'
        headerButtons={
          <SavingsTableHeaderButtons handleNewSaving={() => handleOpen("cryptoSavings", userID)}/>
        }
      >
        <SavingsTable tableData={tableData}/>
      </TableWrapper>
    </>

  );
};

export default Savings;
