import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import React, {useCallback, useContext, useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {UserContext} from "../../contexts/UserContext/UserContext";
import appRoutes from "../../routes/routes";
import {useNavigate} from "react-router-dom";

export default function CLUID() {
  const {cluid, setCluid} = useContext(UserContext);
  const [cluidText, setCLUIDText] = useState(cluid == null ? "":cluid);
  const navigate = useNavigate();

  const handleTextChange = useCallback((e) => {
    setCLUIDText(e.target.value);
  }, [setCLUIDText]);

  const submit = useCallback(() => {
    setCluid(cluidText);
    if(cluidText !== "") {
      navigate(appRoutes.dashboard);
    }
  }, [cluidText, setCluid]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}></Grid>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper} key="cluid_table_container" sx={{ borderRadius: "10px" }}>
            <div className="container-header">
              <div className="container-header-icon-div">
                <div className="container-header-icon-div-body">
                  <img className="container-icon" alt="" src="../images/accounts.svg" />
                </div>
              </div>
              <b className="container-title"><Typography variant="h5" key="account_h">Enter CLUID</Typography></b>
            </div>

            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" key="cluid_table">
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <TextField id="standard-basic" label="CLUID" variant="standard" fullWidth={true} value={cluidText} onChange={handleTextChange}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align={"center"}>
                    <Button variant="outlined" color="success" onClick={submit}>OK</Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}