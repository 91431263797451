import styles from "./deposit-controls.module.scss";
import {ReactComponent as MasterCardIcon} from "../../../assets/icons/deposits/payment-provider-logos/mastercard.svg";
import {ReactComponent as PayPalIcon} from "../../../assets/icons/deposits/payment-provider-logos/paypal.svg";
import {ReactComponent as SkrillIcon} from "../../../assets/icons/deposits/payment-provider-logos/skrill.svg";
import {ReactComponent as StripeIcon} from "../../../assets/icons/deposits/payment-provider-logos/stripe.svg";
import {ReactComponent as CryptoIcon} from "../../../assets/icons/deposits/payment-provider-logos/crypto.svg";
import {ReactComponent as ApplePayIcon} from "../../../assets/icons/deposits/payment-provider-logos/applepay.svg";
import {useMemo, useState} from "react";
import DepositCryptoContent from "./deposit-crypto-content/DepositCryptoContent";
import {clsx} from "clsx";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const DepositControls = ({ handleExchangeDeposit, handleDirectDeposit, mobileWidth }) => {
  const { width } = useWindowDimensions();

  const paymentProviderOptions = [
    {
      name: "mastercard",
      icon: <MasterCardIcon />,
      content: <div>Card payment not available, use crypto</div>,
      mobile: false,
    },
    {
      name: "paypal",
      icon: <PayPalIcon />,
      content: <div>PayPal not available, use crypto</div>,
      mobile: false,
    },
    {
      name: "skrill",
      icon: <SkrillIcon />,
      content: <div>Skrill not available, use crypto</div>,
      mobile: false,
    },
    {
      name: "stripe",
      icon: <StripeIcon />,
      content: <div>Stripe not available, use crypto</div>,
      mobile: false,
    },
    {
      name: "crypto",
      icon: <CryptoIcon />,
      content: <DepositCryptoContent handleExchangeDeposit={handleExchangeDeposit} handleDirectDeposit={handleDirectDeposit} />,
      mobile: true,
    },
    {
      name: "applepay",
      icon: <ApplePayIcon />,
      content: <div>ApplePay not available, use crypto</div>,
      mobile: false,
    },
  ];

  const [contentKey, setContentKey] = useState("crypto");
  const content = useMemo(
    () => paymentProviderOptions.find(option => option.name === contentKey)?.content,
    [contentKey]
  );

  return <div className={styles.container}>
    <div className={styles.innerContainer}>
      <div className={styles.paymentProviders}>
        {paymentProviderOptions
          .filter(option => width < mobileWidth ? option.mobile : true)
          .map((option, index) =>
            <div
              className={clsx(styles.paymentOption, index === 0 && styles.paymentOptionFirst)}
              onClick={() => setContentKey(option.name)}
              key={option.name}
            >
              {option.icon}
              {option.name === contentKey && <div className={styles.selectedOptionBottomCover}></div>}
            </div>
          )}
      </div>
      <div className={styles.content}>
        {content}
      </div>
    </div>
  </div>;
};

export default DepositControls;
