import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import appRoutes from "routes/routes";
import { Link } from "react-router-dom";
import { formatCurrencyValue } from "../../utils/common";
import { useConfig } from "../config/config";

function Balance({ balanceSum }) {
  const { getConfig } = useConfig();

  return (
    <>
      {balanceSum.length === 0 && (
        <Alert severity="error">
          <AlertTitle>No data</AlertTitle>
          <div><Link to={appRoutes.wallet.list}>Connect your wallet</Link></div>
        </Alert>
      )}

      {balanceSum === [] &&
        <>
          <h1>Loading...</h1>
        </>
      }

      <br />

      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <div className="container-header">
          <div className="container-header-icon-div">
            <div className="container-header-icon-div-body">
              <img className="container-icon" alt="" src="../images/balance.svg" />
            </div>
          </div>
          <b className="container-title">
            <Typography variant="h5" key="account_h">
              Consolidated crypto assets
            </Typography>
          </b>
        </div>

        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow variant="head" style={{ background: "#fafafa" }}>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>TOKEN</TableCell>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>VALUE</TableCell>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>VALUE(USD)</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {balanceSum === [] && (<h2> No data loaded</h2>)}
            {balanceSum !== [] && balanceSum.map((row, index) => (
              <TableRow
                key={"balance_row_" + index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{row.currency_name} </TableCell>
                <TableCell align="left">{row.value} {row.currency_symbol}</TableCell>
                <TableCell align="left">
                  {row.converted_value
                    ?
                    formatCurrencyValue(Math.round(row.converted_value * 100) / 100, getConfig("currency"))
                    : "-"
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Balance;
