import * as Sentry from "@sentry/react";
import toast from "react-hot-toast";

export default function handleApiError(msg, reason) {
  let rt = reason?.response?.data?.detail;
  if (rt === undefined) {
    rt = reason?.message;
  }
  if (rt === undefined) {
    rt = reason;
  }

  let id = Sentry.captureException(Error("Error in " + msg + ":" + reason + "/" +  rt));
  toast.error("Error in " + msg + ":\n" + rt + "\n\nErrorID: " + id);
  console.log("SentryID: " + id + ", error: " + msg + ", reason: " + reason + "/" + "rt");
}