import "styles/pages/dashboard/dashboard.css";
import React, { useContext, useEffect, useState } from "react";
import AccountsAmlDemo from "components/aggregate/AccountsAmlDemo";
import BlockmateAPIService from "services/BlockmateAPIService";
import { useConfig } from "components/config/config";
import { UserContext } from "contexts/UserContext/UserContext";
import handleApiError from "../../services/ErrorHandleUtil";
import ConnectedAccountsOverview from "../../components/aggregate/ConnectedAccountsOverview";
import { handleOpen, LinkModal } from "blockmate-react-link";

function DashboardAmlDemo() {
  const { getConfig } = useConfig();
  const { token, linkToken } = useContext(UserContext);
  const [accountsData, setAccountsData] = useState([]);
  const [enrichedAccountsData, setEnrichedAccountsData] = useState([]);

  useEffect(() => {
    BlockmateAPIService.balanceList(getConfig("blockmate_api"), token, getConfig("currency"))
      .then(data => {
        setAccountsData(data.accounts);
        if (data.accounts.length === 0) {
          handleOpen();
        }
      }).catch((reason) => {
        handleApiError("getting balances", reason);
      });
  }, [token, getConfig]);

  useEffect(() => {
    accountsData.forEach(account => {
      // Aggregate balance in converted_currency and set converted_currency correctly
      let converted_sum = 0;
      let converted_currency = getConfig("currency");
      account.balance.forEach(balance => {
        if (balance.converted_value) {
          converted_sum += balance.converted_value;
        }
        if (balance.converted_currency) {
          converted_currency = balance.converted_currency;
        }
      });
      account.account_balance_sum = converted_sum;
      account.converted_currency = converted_currency;
    });
    setEnrichedAccountsData(accountsData);
  }, [accountsData, getConfig, token]);

  return (
    <>
      <LinkModal
        url={getConfig("link_url")}
        jwt={linkToken}
        cleanupActions={{
          success: () => window.location.reload() // Do not eta-reduce this
        }}
      />
      <ConnectedAccountsOverview enrichedAccountsData={enrichedAccountsData} />
      <br/>
      <AccountsAmlDemo enrichedAccountsData={enrichedAccountsData} jwt={token} />
    </>
  );
}

export default DashboardAmlDemo;
