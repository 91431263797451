import axios from "axios";
import qs from "qs";

const getTemporaryUser = () => {
  return axios.get(
    "/v1/temporary_user"
  ).then((data) => data.data.uuid);
};

const getJWT = (user_id) => {
  return axios.get(
    "/v1/temporary_user/" + user_id + "/jwt"
  ).then((data) => data.data.token);
};

const getJWTbyCLUID = (cluid) => {
  return axios.get(
    "/v1/temporary_user_cluid?" + qs.stringify({cluid: cluid})
  ).then((data) => data.data.token);
};

const TemporaryUserService = {
  getTemporaryUser,
  getJWT,
  getJWTbyCLUID
};

export default TemporaryUserService;