import "styles/components/components.scss";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { Link } from "react-router-dom";
import appRoutes from "routes/routes";

function HeaderLogo({ withText = true }) {
  return (
    <Link to={appRoutes.dashboard} className="header-link">
      <div className="header-logo-wrapper">
        <Logo className="header-logo-icon"/>
        {withText && <h5 className="header-logo-text">Personal Finance</h5>}
      </div>
    </Link>
  );
}

export default HeaderLogo;
