import styles from "./assets-table.module.scss";
import {UserContext} from "../../../contexts/UserContext/UserContext";
import {useContext, useMemo} from "react";
import {formatCurrencyValue} from "../../../utils/common";
import { ReactComponent as ExpandIcon } from "../../../assets/icons/george/expander.svg";
import {clsx} from "clsx";
import AssetGraph from "./AssetGraph";
import BalanceDelta from "../common/BalanceDelta";

const AssetsTableRow = ({ asset, currency = "USD", withDivider = true, color, expanded = false }) => {
  const { providers } = useContext(UserContext);
  const provider = useMemo(
    () => providers.find(provider => provider.name === String(asset.currency_symbol).toLowerCase()),
    [providers]
  );

  return <div className={clsx(styles.outerContainer, expanded && styles.expandedColor)}>
    <div className={styles.innerContainer}>
      <div className={styles.assetInfoContainer}>
        <div className={styles.associatedColor} style={{background: color ?? "white"}}></div>
        <div className={styles.iconContainer}>
          <div className={styles.icon}>
            {provider?.icon && <img src={provider.icon} />}
          </div>
        </div>
        <div className={styles.assetWithBalance}>
          <div className={styles.assetName}>
            {asset.currency_name}
          </div>
          <div className={styles.assetValue}>
            {asset.currency_symbol} {asset.value}
          </div>
        </div>
      </div>
      <div className={styles.rightPart}>
        <div className={styles.balanceInfoContainer}>
          <div className={styles.convertedValue}>
            {formatCurrencyValue(asset.converted_value ?? 0, currency, 2)}
          </div>
          <BalanceDelta
            absoluteChange={asset.absolute_change}
            percentChange={asset.percent_change}
            currency={currency}
            timeframe="24h"
            centering="right"
          />
        </div>
        <div className={clsx(styles.expanderContainer, expanded && styles.expandedArrow)}>
          <ExpandIcon/>
        </div>
      </div>
    </div>
    {expanded && <AssetGraph asset={asset} currency={currency} />}
    {withDivider && <div className={styles.divider}></div>}
  </div>;
};

export default AssetsTableRow;
