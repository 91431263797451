import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

function Risk({risk}) {
  return (
    <>
      <Card sx={{ borderRadius: "10px" }}>
        <CardContent style={{ background: risk > 25 ? "yellow" : "white" }}>
          <div className="container-top">
            <div className="container-header-icon-div">
              <div className="container-header-icon-div-body">
                <img className="container-icon" alt="" src="../images/risk.svg" />
              </div>
            </div>
            <div className="container-top-body">
              <Typography variant="h4"> {risk}/100</Typography>
              <div className="container-title"><Typography variant="h6">AML risk score </Typography></div>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default Risk;
