import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardAmlDemo from "pages/dashboard/DashboardAmlDemo";
import Dashboard from "../pages/dashboard/Dashboard";
import WalletList from "pages/wallet/list/List";
import appRoutes from "./routes";
import Debug from "pages/debug/Debug";
import DashboardLayout from "layouts/dashboard-layout/DashboardLayout";
import Savings from "pages/savings/Savings";
import CLUID from "../pages/cluid/cluid";
import GeorgeDashboard from "../pages/george/GeorgeDashboard";
import ProtectOnMissingToken from "./ProtectOnMissingToken";
import * as Sentry from "@sentry/react";
import { useConfig } from "../components/config/config";
import Deposits from "../pages/deposits/demo/Deposits";

function AppRoutes() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  const {getConfig} = useConfig();

  return (
    <>
      <BrowserRouter>
        <SentryRoutes>
          <Route element={<DashboardLayout/>}>
            <Route path={appRoutes.dashboard} element={
              <ProtectOnMissingToken>
                {getConfig("use_cluid")
                  ? <DashboardAmlDemo />
                  : <Dashboard />
                }
              </ProtectOnMissingToken>}
            />
            <Route path={appRoutes.debug} element={<Debug />} />
            <Route path={appRoutes.cluid} element={<CLUID />} />
            <Route path={appRoutes.wallet.list} element={<ProtectOnMissingToken><WalletList /></ProtectOnMissingToken>} />
            { getConfig("show_savings") && <Route path={appRoutes.savings} element={<ProtectOnMissingToken><Savings/></ProtectOnMissingToken>}/> }
          </Route>
          <Route path={appRoutes.george.dashboard} element={<GeorgeDashboard />} />
          <Route path={appRoutes.deposits.demo} element={<Deposits />} />
        </SentryRoutes>
      </BrowserRouter>
    </>
  );
}

export default AppRoutes;
