import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import appRoutes from "../../routes/routes";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { useState, useEffect } from "react";
import { formatCurrencyValue } from "../../utils/common";
import { useConfig } from "../config/config";

const OverviewSection = ({ icon, value, description }) => {
  return <div className="overview-data-container">
    <div className="overview-icon-container">
      {icon}
    </div>
    <div>
      <div className="overview-value-container">
        {value}
      </div>
      <div className="overview-text-container">
        {description}
      </div>
    </div>
  </div>;
};

const ConnectedAccountsOverview = ({enrichedAccountsData}) => {
  const { getConfig } = useConfig();

  const [isLoaded, setIsLoaded] = useState(false);
  const [total, setTotal] = useState(0);
  const [currency, setCurrency] = useState(getConfig("currency"));

  const cardStyle = {
    borderRadius: "10px",
    width: "100%"
  };

  const sumAccountBalancesWithCurrency = account => (account.balance ?? []).reduce(
    (acc, balance) => ({
      total: acc.total + (balance.converted_value ?? 0),
      currency: balance.converted_currency ?? acc.currency
    }),
    {total: 0, currency}
  );

  useEffect(() => {
    if (enrichedAccountsData && enrichedAccountsData.length > 0) {
      const { total: newTotal, currency: newCurrency } = enrichedAccountsData.reduce(
        (accum, account) => {
          const { total: innerTotal, currency: innerCurrency } = sumAccountBalancesWithCurrency(account);
          return {
            total: accum.total + innerTotal,
            currency: innerCurrency ?? accum.currency
          };
        },
        {total: 0, currency}
      );
      setTotal(Math.floor(newTotal));
      setCurrency(newCurrency);
      setIsLoaded(true);
    }
  }, [enrichedAccountsData]);

  return (
    <>
      {enrichedAccountsData.length === 0 && (
        <Alert severity="error">
          <AlertTitle>No data</AlertTitle>
          <div><a href={appRoutes.wallet.list}>Connect your wallet</a></div>
        </Alert>
      )}

      {isLoaded && <div className="overview-cards-container">
        <Card className="TotalCard" sx={cardStyle}>
          <CardContent style={{ background: "#fff" }}>
            <div className="connected-accounts-overview-container">
              <OverviewSection
                icon={<img className="container-icon" alt="" src="../images/total.svg" />}
                value={formatCurrencyValue(total, currency, 0)}
                description="Total balance"
              />
            </div>
          </CardContent>
        </Card>
        <Card className="TotalCard" sx={cardStyle}>
          <CardContent style={{ background: "#fff" }}>
            <div className="connected-accounts-overview-container">
              <OverviewSection
                icon={<img className="container-icon" alt="" src="../images/connect.svg" />}
                value={enrichedAccountsData ? enrichedAccountsData.length : 0}
                description="Crypto accounts"
              />
            </div>
          </CardContent>
        </Card>
      </div>}
    </>
  );
};

export default ConnectedAccountsOverview;
