import "styles/components/components.scss";

const TableWrapper = ({icon, title, headerButtons, children}) => {
  return (
    <div className='table-wrapper'>
      <div className='table-header'>
        <div className='title-container'>
          <div className='icon-wrapper'>
            <div className='icon'>
              {icon}
            </div>
          </div>
          <div className='title'>{title}</div>
        </div>
        <div className='header-buttons'>
          {headerButtons}
        </div>
      </div>
      <div>
        {children}
      </div>
    </div>
  );
};

export default TableWrapper;
