import React, { useState } from "react";

function CopyComponent(props) {
  const [copied, setCopy] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(props.text);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  };

  return (
    <>
      {copied === false &&
        <img className="container-icon" alt="" src="../images/copy.svg" onClick={handleClick} />
      }
      {copied === true &&
        <img className="container-icon" alt="" src="../images/ok.svg" onClick={handleClick} />
      }
    </>
  );
}

export default CopyComponent;
