import dayjs from "dayjs";

export const formatCurrencyValue = (value, currency = "usd", fractionDigits = 2) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: fractionDigits
  });
  return formatter.format(value);
};

export const shortenString = (str, maxLength) => {
  if (str.length > maxLength) {
    const removeFromEnd = str.length - maxLength + 3; // The 3 is for ellipsis
    const remainingPrefixLength = str.length - removeFromEnd;
    return `${str.slice(0, remainingPrefixLength)}...`;
  }
  return str;
};

export const valueExists = (value) => value !== undefined && value !== null && value !== "";

export const dateObjectToDateString = (date, includeTime = false) => {
  if (includeTime) {
    return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
  } else {
    return dayjs(date).format("YYYY-MM-DD");
  }
};
