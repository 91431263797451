import styles from "./account-row.module.scss";
import globalStyles from "../styles.module.scss";
import { ReactComponent as ExpandIcon } from "../../../assets/icons/george/expander.svg";
import {useContext, useMemo} from "react";
import {UserContext} from "../../../contexts/UserContext/UserContext";
import {formatCurrencyValue, shortenString} from "../../../utils/common";

const AccountRow = ({ account, currency = "USD" }) => {
  const { providers } = useContext(UserContext);
  const provider = useMemo(
    () => providers.find(provider => provider.name === account.name),
    [providers, account]
  );
  const totalConvertedValue = useMemo(
    () => account.balance.reduce((acc, balanceEntry) => acc + (balanceEntry?.converted_value ?? 0), 0),
    [account]
  );
  const nativeInfo = useMemo(
    () => {
      if (!provider || !account) {
        return {
          nativeValue: 0,
          containsNonNative: false
        };
      }
      const nativeBalanceEntry = account.balance.find(
        balanceEntry => String(balanceEntry?.currency_symbol).toLowerCase() === provider?.name
      );
      const nonNativeBalanceEntry = account.balance.find(
        balanceEntry => String(balanceEntry?.currency_symbol).toLowerCase() !== provider?.name
      );
      return {
        nativeValue: nativeBalanceEntry?.value ?? 0,
        containsNonNative: nonNativeBalanceEntry !== undefined
      };
    },
    [account, provider]
  );

  return <div className={globalStyles.shadowBox}>
    <div className={styles.container}>
      <div className={styles.leftPart}>
        <div className={styles.providerIconContainer}>
          {provider?.icon && <img src={provider.icon} alt={provider.name} />}
        </div>
        <div className={styles.nameAndAddressContainer}>
          <div className={styles.providerName}>
            {provider?.description && provider.description.split("(")[0].trim()}
          </div>
          <div className={styles.address}>
            {shortenString(account.description, 16)}
          </div>
        </div>
      </div>
      <div className={styles.rightPart}>
        <div className={styles.balanceContainer}>
          <div className={styles.fiatValue}>
            {formatCurrencyValue(totalConvertedValue, currency, 2)}
          </div>
          <div className={styles.cryptoValue}>
            {(provider?.name && provider.url.includes("onchain/") && !nativeInfo.containsNonNative) &&
              <span>{nativeInfo.nativeValue.toFixed(4)} {provider.name.toUpperCase()}</span>
            }
          </div>
        </div>
        <div className={styles.expanderContainer}>
          <ExpandIcon />
        </div>
      </div>
    </div>
  </div>;
};

export default AccountRow;