import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const Message = ({ show, severity, title }) => {
  return (
    <>
      {show && (
        <Alert severity={severity}>
          <AlertTitle>{title}</AlertTitle>
        </Alert>
      )}
    </>
  );
};

export default Message;