import {useConfig} from "../config/config";
import {useContext, useEffect} from "react";
import {UserContext} from "../../contexts/UserContext/UserContext";
import {useNavigate, useSearchParams} from "react-router-dom";
import appRoutes from "../../routes/routes";

export default function LoginFromURL() {
  const {getConfig} = useConfig();
  const {cluid, setCluid} = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.has("cluid")) {
      const cluidFromParam = searchParams.get("cluid");
      if (cluidFromParam && cluidFromParam !== "") {
        setCluid(cluidFromParam);

        // navigate below has a side-effect, that app uses old users token for dashboard - this is a workaround
        window.location.href = window.location.origin;
        // navigate({search: "", pathname: "/"}, {replace: true});
      }
    } else {
      if (getConfig("use_cluid") && (cluid == null || cluid === "" || cluid === undefined)) {
        navigate(appRoutes.cluid);
      }
    }
  }, [searchParams, setSearchParams, navigate, setCluid]);

  return <></>;
}