import Navbar from "components/navbar/Navbar";
import {Outlet} from "react-router-dom";
import React from "react";
import LoginFromURL from "../../components/LoginOrContent/LoginFromURL";
import {Toaster} from "react-hot-toast";

const DashboardLayout = () => {
  return (
    <div className='dashboard-main-container'>
      <Toaster
        toastOptions={{
          style: {
            maxWidth: 500
          },
          error: {
            duration: 6000,
          }
        }}
      />
      <LoginFromURL/>
      <Navbar/>
      <Outlet/>
    </div>
  );
};

export default DashboardLayout;
