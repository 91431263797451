import {valueExists} from "../common";

const FROM = "from";
const TO = "to";

export const determineTxType = (transaction, concise = false) => {
  let type = transaction?.type;
  if (!valueExists(type)) {
    // Fiat deposits and withdrawals all have to be detected by BE in order for this to work correctly
    const ownInputExists = transaction.inputs.some(input => input.owned);
    const ownOutputExists = transaction.outputs.some(output => output.owned);
    type = "Transfer";
    if (ownInputExists && !ownOutputExists) {
      type = "crypto withdrawal";
    } else if (!ownInputExists && ownOutputExists) {
      type = "crypto deposit";
    }
  }
  const isDeposit = type.includes("deposit");
  const isWithdrawal = type.includes("withdraw");
  if (concise) {
    type = type.includes("fiat") ? "fiat" : "crypto";
  }
  return {
    type,
    isDeposit,
    isWithdrawal
  };
};

const sumOwnMovements = (movements) => {
  const ownMovements = movements.filter(movement => movement.owned);
  return ownMovements.reduce((acc, movement) => {
    if (!movement.currency) {
      return acc;
    }
    if (!Object.keys(acc).includes(movement.currency)) {
      const currencySymbol = valueExists(movement.currency_symbol)
        ? movement.currency_symbol
        : movement.currency.split(":")[1] ?? "?";
      acc[movement.currency] = {
        currencySymbol,
        value: 0,
        convertedValue: 0,
        side: FROM
      };
    }
    acc[movement.currency].value += movement.value;
    acc[movement.currency].convertedValue += (movement.converted_value ?? 0);
    acc[movement.currency].side = acc[movement.currency].value < 0 ? FROM : TO;
    return acc;
  }, {});
};

const subtractAggregatedCurrencies = (a, b) => {
  const result = { ...a };
  Object.keys(b).forEach(currency => {
    if (!result[currency]) {
      result[currency] = {
        currencySymbol: b[currency].currencySymbol,
        value: 0,
        convertedValue: 0,
        side: FROM
      };
    }
    result[currency].value -= b[currency].value;
    result[currency].convertedValue -= b[currency].convertedValue;
    result[currency].side = result[currency].value < 0 ? FROM : TO;
  });
  return result;
};

export const calculateDelta = (transaction) => {
  const ownInputSum = sumOwnMovements(transaction.inputs);
  const ownOutputSum = sumOwnMovements(transaction.outputs);
  const delta = subtractAggregatedCurrencies(ownOutputSum, ownInputSum);
  const deltaValues = Object.values(delta).filter(currencyInfo => currencyInfo.value !== 0);
  const getCurrenciesStrBySide = (side) => deltaValues
    .filter(currencyInfo => currencyInfo.side === side)
    .map(currencyInfo => currencyInfo.currencySymbol)
    .join(", ");
  const fromCurrencies = getCurrenciesStrBySide(FROM);
  const toCurrencies = getCurrenciesStrBySide(TO);
  let prefixTransferSymbol = "";
  let infixTransferSymbol = "";
  let maxLength = 24;
  if (valueExists(fromCurrencies) && valueExists(toCurrencies)) {
    infixTransferSymbol = "→";
    maxLength = 12; // Each side will have 12 characters at most displayed
  } else if (!valueExists(fromCurrencies)) {
    prefixTransferSymbol = "+";
  } else if (!valueExists(toCurrencies)) {
    prefixTransferSymbol = "-";
  }
  return {
    delta: deltaValues.slice(0, 1),
    fromCurrencies: getCurrenciesStrBySide(FROM),
    toCurrencies: getCurrenciesStrBySide(TO),
    prefixTransferSymbol,
    infixTransferSymbol,
    maxLength
  };
};
