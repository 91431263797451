import "styles/pages/dashboard/dashboard.css";
import "styles/pages/connect/list.css";
import "styles/components/components.scss";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { handleOpen, LinkModal } from "blockmate-react-link";
import { UserContext } from "contexts/UserContext/UserContext";
import BlockmateAPIService from "services/BlockmateAPIService";
import { useConfig } from "components/config/config";
import Message from "components/message/message";
import TableWrapper from "components/table-wrapper/TableWrapper";
import ConnectedAccountsTable from "./ConnectedAccountsTable";
import ConnectedAccountsTableHeaderButtons from "./ConnectedAccountsTableHeaderButtons";
import handleApiError from "../../../services/ErrorHandleUtil";

function WalletList() {
  const { token, linkToken } = useContext(UserContext);
  const [fetchedData, setFetchedData] = useState([]);
  const [message, setMessage] = useState({ show: false, severity: "", title: "" });
  const { getConfig } = useConfig();

  const updateList = useCallback(() => {
    BlockmateAPIService.balanceList(getConfig("blockmate_api"), token, getConfig("currency")).then(data => {
      setFetchedData(data.accounts);
      //if empty list
      if (data.accounts == ""){
        handleOpen();
      }
    }).catch((reason) => {
      handleApiError("getting balances", reason);
    });
  }, [setFetchedData, getConfig, token]);

  useEffect(() => {
    updateList();
  }, [setFetchedData, token, getConfig]);

  const resetMessage = useCallback(() => {
    setTimeout(() => {
      setMessage({show: false, severity: "", title: ""});
    }, 3000);
  }, [setMessage]);

  function disconnect(walletUrl) {
    BlockmateAPIService.disconnectWallet(getConfig("blockmate_api"), token, walletUrl).then(() => {
      setMessage({ show: true, severity: "success", title: "Wallet was disconnected" });
      resetMessage();
      updateList();
    }).catch((reason) => {
      handleApiError("disconnecting wallet", reason);
    });
  }

  return (
    <>
      <LinkModal
        url={getConfig("link_url")}
        jwt={linkToken}
        cleanupActions={{
          success: () => window.location.reload() // Do not eta-reduce this
        }}
      />
      <Message show={message.show} severity={message.severity} title={message.title}/>
      <TableWrapper
        icon={<img className="container-icon" alt="" src="../images/connect.svg"/>}
        title='Connected accounts'
        headerButtons={
          <ConnectedAccountsTableHeaderButtons/>
        }
      >
        <ConnectedAccountsTable fetchedData={fetchedData} disconnect={disconnect}/>
      </TableWrapper>
    </>
  );
}

export default WalletList;
