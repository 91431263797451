import styles from "./topbar.module.scss";
import {useEffect, useState} from "react";
import axios from "axios";
import {useConfig} from "../../config/config";
import {formatCurrencyValue} from "../../../utils/common";

const TopBar = ({ jwt }) => {
  const CURRENCY = "EUR";
  const {getConfig} = useConfig();
  const [balanceInfo, setBalanceInfo] = useState({
    "confirmed": 0,
    "pending": 0,
  });

  useEffect(() => {
    if (jwt) {
      axios.get(
        "/v1/exchange/deposit/demo_aggregate_deposits",
        {
          baseURL: getConfig("blockmate_api"),
          headers: {
            "Authorization": "Bearer " + jwt,
          },
          params: {
            fiat_currency: CURRENCY,
          },
        }
      ).then(data => setBalanceInfo(data?.data));
    }
  }, [jwt]);

  return <div className={styles.container}>
    <div className={styles.balanceInfo}>
      {[
        {label: "My account", value: balanceInfo?.confirmed},
        {label: "In transfer", value: balanceInfo?.pending},
      ].map(entry => <div className={styles.balanceEntry} key={entry.label}>
        <div className={styles.label}>{entry.label}:</div>
        <div className={styles.spacer}></div>
        <div> {formatCurrencyValue(entry.value ?? 0, CURRENCY, 2)}</div>
      </div>)}
    </div>
  </div>;
};

export default TopBar;
