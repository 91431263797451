import "styles/components/components.scss";
import HeaderLogo from "components/header/HeaderLogo";
import MenuButton from "components/buttons/menu-buttons/MenuButton";
import appRoutes from "routes/routes";
import { ReactComponent as WedgeIcon } from "assets/icons/menu-icons/wedge.svg";
import { useConfig } from "../config/config";
import { useState, useEffect } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { handleOpen } from "blockmate-react-link";
import MainButton from "../buttons/main-button/MainButton";
import ClickOutside from "../common/ClickOutside";

const Navbar = () => {
  const {getConfig} = useConfig();

  const { width } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const addAccountButtonDisabled = window.location.pathname === appRoutes.cluid;

  useEffect(() => {
    if (width <= 768) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, [width]);

  const showIcons = !getConfig("use_cluid");
  const menuItems = [];
  if (!getConfig("use_cluid")) {
    menuItems.push(
      <MenuButton
        path={appRoutes.dashboard}
        icon={showIcons ? <img className="container-icon" alt="" src="../images/home.svg"/> : null}
        key="menu-dashboard"
      >
        Dashboard
      </MenuButton>
    );
  }
  menuItems.push(
    <MenuButton
      path={appRoutes.wallet.list}
      icon={showIcons ? <img className="container-icon" alt="" src="../images/connect.svg"/> : null}
      key="menu-connect-accounts"
    >
      Connect accounts
    </MenuButton>
  );
  if (getConfig("show_savings")) {
    menuItems.push(
      <MenuButton
        path={appRoutes.savings}
        icon={showIcons ? <img className="container-icon" alt="" src="../images/assets.svg"/> : null}
        key="menu-savings"
      >
        Digital assets savings
      </MenuButton>
    );
  }
  if (getConfig("use_cluid")) {
    menuItems.push(
      <MenuButton
        path={appRoutes.cluid}
        icon={showIcons ? <img className="container-icon" alt="" src="../images/connect.svg"/> : null}
        key="menu-cluid"
      >
        Change CLUID
      </MenuButton>
    );
  }

  const toggleOpen = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  return <>
    <div className="mobile-menu">
      <div className="mobile-menu-packed">
        <HeaderLogo withText={!isMobileView} />
        <div className="mobile-menu-items-container">
          <ClickOutside onClickOutside={() => setIsOpen(false)}>
            <div className="mobile-menu-button" onClick={toggleOpen}>
              <div className="mobile-menu-icon">
                <WedgeIcon className={isOpen ? "" : "wedge-pointing-right"} />
              </div>
              <span>Menu</span>
              { isOpen &&
                <div className="dropdown-menu-container">
                  {menuItems.map((item, idx) => (
                    <div
                      className="unpacked-item"
                      key={`unpacked-menu-item-${idx}`}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              }
            </div>
          </ClickOutside>
          <MainButton
            label='ADD ACCOUNT'
            icon={<img className="container-icon" alt="" src="../images/plus.svg"/>}
            onClick={() => handleOpen()}
            extraClasses="narrow-button"
            disabled={addAccountButtonDisabled}
          />
        </div>
      </div>
    </div>
  </>;
};

export default Navbar;
