import styles from "./sidebar.module.scss";
import {ReactComponent as DashboardIcon} from "../../../assets/icons/deposits/home.svg";
import {ReactComponent as DepositIcon} from "../../../assets/icons/deposits/deposit.svg";
import {ReactComponent as WithdrawalIcon} from "../../../assets/icons/deposits/withdrawal.svg";
import {ReactComponent as HistoryIcon} from "../../../assets/icons/deposits/history.svg";
import {clsx} from "clsx";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const SideBar = ({ mobileWidth }) => {
  const { width } = useWindowDimensions();
  const isMobile = width <= mobileWidth;

  const menuItems = [
    {
      title: "Dashboard",
      icon: <DashboardIcon />,
      active: false,
    },
    {
      title: "Deposit",
      icon: <DepositIcon />,
      active: true,
    },
    {
      title: "Withdrawal",
      icon: <WithdrawalIcon />,
      active: false,
    },
    {
      title: "History",
      icon: <HistoryIcon />,
      active: false,
    },
  ];

  return <div className={clsx(styles.container, isMobile && styles.mobileContainer)}>
    <div className={styles.menuItems}>
      {menuItems.map(menuItem => <div className={clsx(styles.menuItemContainer, menuItem.active && styles.active)} key={menuItem.title}>
        <div className={styles.icon}>
          {menuItem.icon}
        </div>
        {!isMobile && <div className={styles.title}>
          {menuItem.title}
        </div>}
      </div>)}
    </div>
  </div>;
};

export default SideBar;
