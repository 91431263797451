import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import appRoutes from "routes/routes";
import {formatCurrencyValue} from "../../utils/common";
import {useConfig} from "../config/config";

function Total({ balanceSum }) {
  const { getConfig } = useConfig();

  let total = 0;
  balanceSum.forEach(function(row) {
    if (row.converted_value) {
      total += row.converted_value;
    }
  });
  total = Math.round(Number(total) * 100) / 100;

  return (
    <>
      {balanceSum.length === 0 && (
        <Alert severity="error">
          <AlertTitle>No data</AlertTitle>
          <div><a href={appRoutes.wallet.list}>Connect your wallet</a></div>
        </Alert>
      )}

      <Card className="TotalCard" sx={{ borderRadius: "10px" }}>
        <CardContent style={{ background: "#fff" }}>
          <div className="container-top">
            <div className="container-header-icon-div">
              <div className="container-header-icon-div-body">
                <img className="container-icon" alt="" src="../images/total.svg" />
              </div>
            </div>
            <div className="container-top-body">
              <Typography variant="h4">{formatCurrencyValue(total, getConfig("currency"))}</Typography>
              <div className="container-title"><Typography variant="h6">Total balance </Typography></div>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default Total;
