import TableButton from "components/buttons/table-button/TableButton";
import MainButton from "components/buttons/main-button/MainButton";

const SavingsTableHeaderButtons = ({handleNewSaving}) => {
  return (
    <>
      <TableButton
        icon={<img className="container-icon" alt="" src="../images/download.svg"/>}
        title='Transfer assets'
      />
      <MainButton
        label='ADD NEW CRYPTO SAVING'
        icon={<img className="container-icon" alt="" src="../images/plus.svg"/>}
        onClick={handleNewSaving}
      />
    </>
  );
};

export default SavingsTableHeaderButtons;
