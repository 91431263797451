import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {handleOpen} from "blockmate-react-link";
import TableContainer from "@mui/material/TableContainer";
import TableButton from "components/buttons/table-button/TableButton";

const frequencyConfig = {
  0: "one-time-buy",
  86400: "every day",
  604800: "every week",
  2592000: "every month",
};

const SavingsTable = ({tableData}) => {

  const calculateFrequency = (frequencyInSeconds) => {
    return frequencyConfig[frequencyInSeconds] || "Unknown frequency";
  };

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">PROVIDER</TableCell>
            <TableCell align="left">ASSET BALANCE</TableCell>
            <TableCell align="left">AMOUNT</TableCell>
            <TableCell align="left">FREQUENCY</TableCell>
            <TableCell align="left">OPTIONS</TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length >= 1 ? tableData.map((row, index) => (
            <TableRow
              key={"wallet_row_" + index}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.crypto_amount} BTC</TableCell>
              <TableCell align="left">{row.fiat_amount}</TableCell>
              <TableCell align="left">{calculateFrequency(row.payment_frequency)}</TableCell>
              <TableCell align="left">
                <TableButton
                  icon={<img className="container-icon" alt="" src="../images/document.svg"/>}
                  title='Statement'
                  // TODO uncomment when BE ready
                  // onClick={() => handleOpen('enableTransfer', row.account_id)}
                />
              </TableCell>
              <TableCell align="left">
                <TableButton
                  icon={<img className="container-icon" alt="" src="../images/transfer.svg"/>}
                  title='Withdraw assets'
                  onClick={() => handleOpen("withdrawAssets", row.id)}
                />
              </TableCell>
              <TableCell align="left">
                {/*TODO handle savings cancel*/}
                <div className="del-div">
                  <img className="del-img" alt="" src="../images/del.svg" />
                  <b className="del-b">Cancel savings</b>
                </div>
              </TableCell>
            </TableRow>
          )) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SavingsTable;
