import styles from "./account-details.module.scss";
import TxsTable from "../common/TxsTable/TxsTable";

const AccountDetails = ({ account, currency = "USD" }) => {

  return <div className={styles.container}>
    <TxsTable account={account} currency={currency} />
  </div>;
};

export default AccountDetails;
