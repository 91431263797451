import styles from "./tx-row.module.scss";
import {useMemo} from "react";
import {formatCurrencyValue, shortenString, valueExists} from "../../../../utils/common";
import {dateObjectToDateString} from "../../../../utils/common";
// eslint-disable-next-line no-unused-vars
import {determineTxType, calculateDelta} from "../../../../utils/george/transactions";

const TxRow = ({ tx, currency = "USD" }) => {
  // const txType = useMemo(() => determineTxType(tx, true), [tx]);
  const deltaInfo = useMemo(() => calculateDelta(tx), [tx]);
  const datetimeInfo = useMemo(() => {
    const dateString = dateObjectToDateString(tx.created_at, true);
    const [date, time] = dateString.split(" ");
    return { date, time };
  }, [tx.created_at]);

  return <div className={styles.container}>
    <div className={styles.datetime}>
      <div>{datetimeInfo.date}</div>
      <div>{datetimeInfo.time}</div>
    </div>
    {/*<div className={styles.centered}>*/}
    {/*  <div className={styles.txTypeContainer}>*/}
    {/*    {txType.type}*/}
    {/*  </div>*/}
    {/*</div>*/}
    <div className={styles.centered}>
      <div className={styles.transfer}>
        {valueExists(deltaInfo.prefixTransferSymbol) && <span>{deltaInfo.prefixTransferSymbol}</span>}
        {valueExists(deltaInfo.fromCurrencies) &&
          <span className={styles.fromCurrencies}>{shortenString(deltaInfo.fromCurrencies, deltaInfo.maxLength)}</span>
        }
        {valueExists(deltaInfo.infixTransferSymbol) && <span>{deltaInfo.infixTransferSymbol}</span>}
        {valueExists(deltaInfo.toCurrencies) &&
          <span className={styles.toCurrencies}>{shortenString(deltaInfo.toCurrencies, deltaInfo.maxLength)}</span>
        }
      </div>
    </div>
    <div className={styles.centered}>
      {deltaInfo.delta.map((currencyInfo, idx) =>
        <div className={styles.currencyDelta} key={`delta-info-${String(tx.transaction_id)}-${idx}`}>
          <div className={styles.currencyDeltaValue}>
            <div
              className={currencyInfo.value > 0 ? styles.deltaValuePositive : styles.deltaValueNegative}
            >
              {currencyInfo.value > 0 && "+"}
              {Number(currencyInfo.value.toFixed(4))}
            </div>
            <div>
              {shortenString(currencyInfo.currencySymbol, 8)}
            </div>
          </div>
          {/* Also show fiat value for one-sided cases (+ or -, not ->) */}
          {deltaInfo.delta.length === 1 && <div className={styles.currencyDeltaValue}>
            <div>
              {formatCurrencyValue(currencyInfo.convertedValue, currency, 2)}
            </div>
          </div>}
        </div>
      )}
    </div>
  </div>;
};

export default TxRow;
