import styles from "./txs-table.module.scss";
import globalStyles from "../../styles.module.scss";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../../contexts/UserContext/UserContext";
import {useConfig} from "../../../config/config";
import BlockmateAPIService from "../../../../services/BlockmateAPIService";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/george/calendar-icon.svg";
import TxRow from "./TxRow";

const TxsTable = ({ account, currency = "USD" }) => {
  const TXS_PAGE_SIZE = 50;
  const LAST_TXS_PAGE_CURSOR = "";
  const { getConfig } = useConfig();
  const { token } = useContext(UserContext);
  const [txs, setTxs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCursor, setPageCursor] = useState(undefined);
  const loadedAll = pageCursor === LAST_TXS_PAGE_CURSOR;

  const loadNextTxsPage = () => {
    if (!token || pageCursor === LAST_TXS_PAGE_CURSOR) {
      return;
    }
    setIsLoading(true);
    BlockmateAPIService.transactionList(
      getConfig("blockmate_api"), token, currency, [account.account_id], TXS_PAGE_SIZE, pageCursor
    )
      .then(data => {
        setTxs(prevTxs => [...prevTxs, ...(data?.transactions ?? [])]);
        setPageCursor(data?.page_cursor);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (token) {
      loadNextTxsPage();
    }
  }, [token]);

  return <div className={globalStyles.shadowBox}>
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.leftPart}>
          <div className={styles.heading}>
            Recent transactions
          </div>
          <div className={styles.timeframe}>
            All time
          </div>
        </div>
        <div className={styles.rightPart}>
          <div className={styles.icon}>
            <CalendarIcon />
          </div>
        </div>
      </div>
      {txs.map((tx, index) => <div className={styles.txRowContainer} key={`tx-${index}`}>
        <TxRow tx={tx} currency={currency} />
      </div>)}

      {!loadedAll && <div className={styles.loadMoreContainer}>
        {isLoading
          ? <span className={styles.loadingText}>
            Loading...
          </span>
          : <span className={styles.loadMoreText} onClick={loadNextTxsPage}>
            Load more
          </span>
        }
      </div>}
    </div>
  </div>;
};

export default TxsTable;
