import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import appRoutes from "routes/routes";
import { Link } from "react-router-dom";
import { formatCurrencyValue } from "../../utils/common";
import { useConfig } from "../config/config";

function Accounts({ enrichedAccountsData }) {
  const { getConfig } = useConfig();

  return (
    <>
      {enrichedAccountsData.length === 0 && (
        <Alert severity="error">
          <AlertTitle>No data</AlertTitle>
          <div><Link to={appRoutes.wallet.list}>Connect your wallet</Link></div>
        </Alert>
      )}

      {enrichedAccountsData === [] &&
        <>
          <h1>Loading...</h1>
        </>
      }

      <br />

      <TableContainer component={Paper} key="account_table_container" sx={{ borderRadius: "10px" }}>
        <div className="container-header">
          <div className="container-header-icon-div">
            <div className="container-header-icon-div-body">
              <img className="container-icon" alt="" src="../images/accounts.svg" />
            </div>
          </div>
          <b className="container-title"><Typography variant="h5" key="account_h">Connected accounts</Typography></b>
        </div>

        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" key="account_table">
          <TableHead>
            <TableRow variant="head" style={{ background: "#fafafa" }}>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>BALANCE</TableCell>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>TYPE</TableCell>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>NAME</TableCell>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>DESCRIPTION</TableCell>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>VERIFIED</TableCell>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>RISK</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {enrichedAccountsData === [] && (<h2> No data loaded</h2>)}
            {enrichedAccountsData !== [] && enrichedAccountsData.map((row, index) => (
              <TableRow
                key={"accounts_row_" + index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  {row.account_balance_sum && (formatCurrencyValue(Math.round(row.account_balance_sum * 100) / 100, getConfig("currency")))}
                  {!row.account_balance_sum && "-"}
                </TableCell>
                <TableCell align="left">{row.type} </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
                <TableCell align="left">
                  {row.verified && <CheckCircleIcon />}
                  {!row.verified && <CancelIcon />}
                </TableCell>
                <TableCell align="left">{row.risk} / 100</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Accounts;