import styles from "./george-accounts.module.scss";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../contexts/UserContext/UserContext";
import AccountRow from "./AccountRow";
import MainButton from "../../buttons/main-button/MainButton";
import {handleOpen} from "blockmate-react-link";
import { ReactComponent as ArrowsIcon } from "../../../assets/icons/george/arrows.svg";
import AccountDetails from "./AccountDetails";
import {valueExists} from "../../../utils/common";

const GeorgeAccounts = ({ setBackAction }) => {
  const { accountsData } = useContext(UserContext);
  const [selectedRowIndex, setSelectedRowIndex] = useState(undefined);

  useEffect(() => {
    if (valueExists(selectedRowIndex)) {
      setBackAction(() => () => setSelectedRowIndex(undefined));
    }
  }, [selectedRowIndex]);

  if (valueExists(selectedRowIndex)) {
    const account = accountsData[selectedRowIndex];
    return <AccountDetails account={account} key={account.account_id} />;
  }

  return <div className={styles.container}>
    {accountsData.map(
      (account, index) => <div key={account.account_id} className={styles.accountRow} onClick={() => setSelectedRowIndex(index)}>
        <AccountRow account={account} />
      </div>
    )}
    <MainButton
      label="Connect new digital wallet or exchange"
      icon={<ArrowsIcon />}
      onClick={() => handleOpen()}
      extraClasses={styles.connectButton}
    />
  </div>;
};

export default GeorgeAccounts;
