import axios from "axios";

const getLinkToken = (baseURL, token) => {
  return axios.post(
    "/v1/link/link/token",
    {
      "jwt": token,
      "redirect_uri": ""
    },
    {
      baseURL: baseURL
    }
  ).then(r => r.data.link_token);
};

const getProviders = (baseURL, linkToken) => {
  return axios.get(
    "/v1/link/link/providers",
    {
      baseURL: baseURL,
      params: {
        "jwt": linkToken
      }
    }
  ).then(r => r.data);
};

const getConnectedPaymentAccounts = (baseURL, linkToken, chain) => {
  return axios.get(
    "/v1/link/link/payment/accounts",
    {
      baseURL: baseURL,
      params: {
        "jwt": linkToken,
        chain,
        "fiat_currency": "USD" // TODO do not hardcode, but probably fine for now
      }
    }
  ).then(r => r.data);
};

const LinkService = {
  getLinkToken,
  getProviders,
  getConnectedPaymentAccounts
};

export default LinkService;
