import "styles/components/components.scss";
import {NavLink} from "react-router-dom";

const MenuButton = ({icon, children, path}) => {
  return (
    <NavLink to={path} className='menu-button'>
      <div className='menu-button-icon'>
        {icon}
      </div>
      <span className='menu-button-description'>
        {children}
      </span>
    </NavLink>
  );
};

export default MenuButton;