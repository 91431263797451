import {useContext} from "react";
import {UserContext} from "../contexts/UserContext/UserContext";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export default function ProtectOnMissingToken({ children }) {
  const {token} = useContext(UserContext);
  const decodedJwt = parseJwt(token);

  if (token == null || decodedJwt == null || decodedJwt.exp * 1000 < Date.now()) {
    return <h1>Loading...</h1>;
  }

  return children;
}