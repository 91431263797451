import { useMemo } from "react";

const ProviderInfo = ({ providerName, allProviders }) => {
  const provider = useMemo(
    () => allProviders.find(p => p.name === providerName), [providerName, allProviders]
  );

  if (!provider) {
    return <div>{providerName}</div>;
  }

  return <div style={{
    display: "flex",
    alignItems: "center",
    gap: "6px"
  }}>
    <div>
      <img src={provider.icon} style={{width: "30px", height: "30px"}} />
    </div>
    <div>
      {provider.description}
    </div>
  </div>;
};

export default ProviderInfo;
