export const ARC_COLORS = [
  "#7447F4",
  "#6DFF96",
  "#EF032D",
  "#FFD66D",
  "#FF8A00",
  "#FF00FF",
  "#00FF00",
  "#00FFFF",
  "#0000FF",
  "#FF0000",
  "#FFFF00",
  "#FF00FF",
  "#00FF00",
  "#00FFFF",
  "#0000FF",
  "#FF0000",
  "#FFFF00",
  "#FF00FF",
  "#00FF00",
  "#00FFFF",
];

export const HIDDEN_COLOR = "#EDEDED";
