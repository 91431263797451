import axios from "axios";
import {valueExists} from "../utils/common";

const accountProvidersList = (baseURL, token) => {
  return axios.get("/v1/account_providers", {
    baseURL: baseURL,
    headers: {
      "Authorization": "Bearer " + token
    }
  }
  ).then(data => data.data);
};

const addressRisk = async (baseURL, token, address, chain) => {
  return await axios.get(
    `/v1/risk/score?address=${address}&chain=${chain}`, {
      baseURL: baseURL,
      headers: {
        "Authorization": "Bearer " + token
      }
    }
  ).then(data => data.data);
};

const connectWallet = (baseURL, token, provider, wallet) => {
  return axios.post(
    `/v1/${provider}/connect`,
    {
      "description": wallet,
      "wallet": wallet
    },
    {
      baseURL: baseURL,
      headers: {
        "Authorization": "Bearer " + token
      }
    }
  );
};

const disconnectWallet = (baseURL, token, url) => {
  return axios.delete(
    `/v1/${url}`,
    {
      baseURL: baseURL,
      headers: {
        "Authorization": "Bearer " + token
      }
    }
  );
};

const transactionList = (baseURL, token, currency = "USD", accountIds = [], pageSize = 20, cursor = "") => {
  const accountsParam = accountIds.join(",");
  const params = {
    currency,
    page_size: pageSize,
  };
  if (cursor) {
    params.cursor = cursor;
  }
  if (valueExists(accountsParam)) {
    params["account-filter"] = accountsParam;
  }
  return axios.get(
    "/v1/aggregate/transactions",
    {
      baseURL: baseURL,
      headers: {
        "Authorization": "Bearer " + token
      },
      params
    }
  ).then(data => data.data);
};

const balanceList = (baseURL, token, currency = "USD") => {
  return axios.get(
    "/v1/aggregate/balance",
    {
      baseURL: baseURL,
      headers: {
        "Authorization": "Bearer " + token
      },
      params: {
        currency
      }
    }
  ).then(data => data.data);
};

const exchangeRatesHistory = (baseURL, token, pair = "USD/BTC", days) => {
  const params = { pair };
  if (days) {
    params.days = days;
  }
  return axios.get(
    "/v1/exchangerate/history",
    {
      baseURL: baseURL,
      headers: {
        Authorization: "Bearer " + token
      },
      params
    }
  ).then(data => data.data);
};

const BlockmateAPIService = {
  accountProvidersList,
  addressRisk,
  connectWallet,
  disconnectWallet,
  transactionList,
  balanceList,
  exchangeRatesHistory
};

export default BlockmateAPIService;