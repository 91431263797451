import styles from "./george-portfolio.module.scss";
import globalStyles from "../styles.module.scss";
import AssetsTableRow from "./AssetsTableRow";
import CircularBalanceDisplay from "./CircularBalanceDisplay";
// import {UserContext} from "../../../contexts/UserContext/UserContext";
// import {useContext} from "react";
import {ARC_COLORS} from "../../../utils/george/colors-config";
import {useState} from "react";

const GeorgePortfolio = ({ currency = "USD" }) => {
  // const { balanceSumData } = useContext(UserContext);
  const [selectedAssetIndex, setSelectedAssetIndex] = useState(undefined);

  const balanceSumData = [
    {
      currency_name: "Bitcoin",
      currency_symbol: "BTC",
      value: 0.0123,
      converted_value: 654.32,
      percent_change: 4.5,
      absolute_change: 31.34,
    },
    {
      currency_name: "Ethereum",
      currency_symbol: "ETH",
      value: 0.1234,
      converted_value: 543.21,
      percent_change: 3.5,
      absolute_change: 21.34,
    },
    {
      currency_name: "Ripple",
      currency_symbol: "XRP",
      value: 1234.56,
      converted_value: 300.34,
      percent_change: -0.5,
      absolute_change: -6.23,
    },
    {
      currency_name: "Litecoin",
      currency_symbol: "LTC",
      value: 12345.67,
      converted_value: 101.23,
      percent_change: -1.5,
      absolute_change: -18.52,
    },
    {
      currency_name: "SmallFirst",
      currency_symbol: "SST",
      value: 12345.67,
      converted_value: 10.23,
      percent_change: -1.5,
      absolute_change: -18.52,
    },
    {
      currency_name: "SmallSecond",
      currency_symbol: "SND",
      value: 12345.67,
      converted_value: 6.23,
      percent_change: -1.5,
      absolute_change: -18.52,
    },
    {
      currency_name: "SmallThird",
      currency_symbol: "SRD",
      value: 12345.67,
      converted_value: 1.23,
      percent_change: -1.5,
      absolute_change: -18.52,
    }
  ];

  const handleAssetClick = (index) => {
    setSelectedAssetIndex(prevSelected => {
      if (prevSelected === index) {
        return undefined;
      }
      return index;
    });
  };

  return <div className={styles.container}>
    <div className={globalStyles.shadowBox}>
      <div className={styles.boxContainer}>
        <div className={styles.graphContainer}>
          <CircularBalanceDisplay
            assets={balanceSumData}
            selectedAssetIndex={selectedAssetIndex}
            percentChange={4.8}
            absoluteChange={123.456}
          />
        </div>
        <div className={styles.tableContainer}>
          {balanceSumData.map((asset, index) =>
            <div onClick={() => handleAssetClick(index)} key={`${asset.currency_symbol}-${index}`}>
              <AssetsTableRow
                asset={asset}
                currency={currency}
                withDivider={index < balanceSumData.length - 1}
                color={ARC_COLORS[index % ARC_COLORS.length]}
                expanded={selectedAssetIndex === index}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  </div>;
};

export default GeorgePortfolio;
