import "styles/components/components.scss";

const TableButton = ({icon, title, onClick}) => {
  return (
    <button className="table-button" onClick={onClick}>
      <div className='icon'>
        {icon}
      </div>
      <span className='table-button-text'>
        {title}
      </span>
    </button>
  );
};

export default TableButton;
