import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {handleOpen} from "blockmate-react-link";
import TableContainer from "@mui/material/TableContainer";
import TableButton from "components/buttons/table-button/TableButton";
import {useConfig} from "../../../components/config/config";

const ConnectedAccountsTable = ({fetchedData, disconnect}) => {
  const { getConfig } = useConfig();

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">PROVIDER</TableCell>
            <TableCell align="left">ADDRESS</TableCell>
            <TableCell align="left">LAST SYNC</TableCell>
            <TableCell align="left">OPTIONS</TableCell>
            {getConfig("show_savings") && <TableCell align="left"></TableCell>}
            {getConfig("show_savings") && <TableCell align="left"></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {fetchedData ? fetchedData.map((row, index) => (
            <TableRow key={"wallet_row_" + index}>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.description}</TableCell>
              <TableCell align="left">{row.state.last_sync}</TableCell>
              {getConfig("show_savings") && <TableCell align="left">
                <TableButton
                  icon={<img className="container-icon" alt="" src="../images/transfer.svg"/>}
                  title='Enable transfer'
                  onClick={() => handleOpen("enableTransfer", row.account_id)}
                />
              </TableCell>}
              {getConfig("show_savings") && <TableCell align="left">
                <TableButton
                  icon={<img className="container-icon" alt="" src="../images/transfer.svg"/>}
                  title='Transfer assets'
                  onClick={() => handleOpen("transferAssets", row.account_id)}
                />
              </TableCell>}
              <TableCell align="left">
                <div className="del-div" onClick={() => disconnect(row.url)}>
                  <img className="del-img" alt="" src="../images/del.svg" />
                  <b className="del-b">Disconnect</b>
                </div>
              </TableCell>
            </TableRow>
          )) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConnectedAccountsTable;
