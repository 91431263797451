import styles from "./balance-delta.module.scss";
import { ReactComponent as DeltaTriangle } from "../../../assets/icons/george/delta-triangle.svg";
import {clsx} from "clsx";
import {formatCurrencyValue} from "../../../utils/common";

const BalanceDelta = ({ percentChange, absoluteChange, currency = "USD", centering= "center", oneline = false }) => {
  const classByCentering = {
    left: styles.alignLeft,
    center: styles.alignCenter,
    right: styles.alignRight,
  };
  return <div className={clsx(styles.deltaContainer, classByCentering[centering] ?? styles.alignCenter, oneline && styles.oneline)}>
    <div className={styles.percentChange}>
      <div
        className={clsx(styles.deltaTriangle, absoluteChange >= 0 ? styles.deltaTrianglePositive : styles.deltaTriangleNegative)}>
        <DeltaTriangle/>
      </div>
      <div className={styles.text}>
        {absoluteChange >= 0 ? "+" : ""}{percentChange}% 24h
      </div>
    </div>
    <div
      className={clsx(styles.absoluteChange, absoluteChange >= 0 ? styles.colorPositive : styles.colorNegative)}>
      {absoluteChange >= 0 ? "+" : "-"}{formatCurrencyValue(Math.abs(absoluteChange), currency, 2)}
    </div>
  </div>;
};

export default BalanceDelta;
