import TableButton from "components/buttons/table-button/TableButton";
import { handleOpen } from "blockmate-react-link";
import { useConfig } from "../../../components/config/config";

const ConnectedAccountsTableHeaderButtons = () => {
  const {getConfig} = useConfig();

  return (
    <>
      {getConfig("show_savings") && <TableButton
        icon={<img className="container-icon" alt="" src="../images/settings.svg"/>}
        title='Verify phone number'
        onClick={() => handleOpen("verifyPhone")}
      />}
      {getConfig("show_savings") && <TableButton
        icon={<img className="container-icon" alt="" src="../images/settings.svg"/>}
        title='Change phone number'
        onClick={() => handleOpen("changePhone")}
      />}
    </>
  );
};

export default ConnectedAccountsTableHeaderButtons;