import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Moment from "moment";
import HoverableComponent from "components/aggregate/HoverableComponent";
import CopyComponent from "components/aggregate/CopyComponent";
import appRoutes from "routes/routes";
import TransactionMovement from "./TransactionMovement";

function Transactions({ transactions }) {
  return (
    <>
      {transactions.length === 0 && (
        <Alert severity="error">
          <AlertTitle>No data</AlertTitle>
          <div><a href={appRoutes.wallet.list}>Connect your wallet</a></div>
        </Alert>
      )}

      {transactions === [] &&
        <>
          <h1>Loading...</h1>
        </>
      }

      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <div className="container-header">
          <div className="container-header-icon-div">
            <div className="container-header-icon-div-body">
              <img className="container-icon" alt="" src="../images/transactions.svg" />
            </div>
          </div>
          <b className="container-title">
            <Typography variant="h5" key="account_h">
              Consolidated Transactions
            </Typography>
          </b>
        </div>

        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow variant="head">
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>DATE(UTC)</TableCell>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>TX HASH</TableCell>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>FROM</TableCell>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>TO</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {transactions === [] && (<h2> No data loaded</h2>)}
            {transactions !== [] && transactions.map((row, index) => (
              <TableRow
                key={"transactions_row_" + index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left" size="small">{Moment(row.created_at).format("MM/DD/YYYY HH:mm:ss")} </TableCell>

                <TableCell align="left" style={{ color: "#7346F3", textDecoration: "underline" }}>
                  <HoverableComponent text={row.transaction_id} length="15" />
                  <CopyComponent text={row.transaction_id} />
                </TableCell>

                <TableCell align="left" style={{ verticalAlign: "top" }}>
                  <TransactionMovement data={row.inputs}/>
                </TableCell>
                <TableCell align="left" style={{ verticalAlign: "top" }}>
                  <TransactionMovement data={row.outputs} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Transactions;
