import createConfig from "react-runtime-config";

export const { getConfig, useConfig, useAdminConfig } = createConfig({
  namespace: "MY_APP_CONFIG",
  localOverride: false,
  storage: null,
  schema: {
    sentry_dsn: {
      type: "string",
      description: "Sentry DSN",
      default: ""
    },
    sentry_env: {
      type: "string",
      description: "Sentry env",
      default: "development"
    },
    blockmate_api: {
      type: "string",
      description: "Blockmate API URL",
      default: "https://api.blockmate.io/"
    },
    base_url: {
      type: "string",
      description: "Base URL (used for redirects)",
      default: "https://personal-finance-demo.blockmate.io/"
    },
    link_url: {
      type: "string",
      description: "Blockmate Link URL",
      default: "https://link.blockmate.io"
    },
    use_cluid: {
      type: "boolean",
      description: "Use CLUID",
      default: false
    },
    show_savings: {
      type: "boolean",
      description: "Show digital asset savings section",
      default: true
    },
    currency: {
      type: "string",
      description: "Display currency",
      default: "USD"
    },
    deposit_exchanges: {
      type: "string",
      description: "Comma-separated deposit exchanges",
      default: "coinbase,binance,bitmarkets",
    },
  }
});
