import "styles/pages/dashboard/dashboard.css";
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Balance from "components/aggregate/Balance";
import Total from "components/aggregate/Total";
import Risk from "components/aggregate/Risk";
import Accounts from "components/aggregate/Accounts";
import Transactions from "components/aggregate/Transactions";
import BlockmateAPIService from "services/BlockmateAPIService";
import { useConfig } from "components/config/config";
import { UserContext } from "contexts/UserContext/UserContext";
import handleApiError from "../../services/ErrorHandleUtil";
import { LinkModal } from "blockmate-react-link";

function Dashboard() {
  const { getConfig } = useConfig();
  const { token, linkToken } = useContext(UserContext);
  const [accountsData, setAccountsData] = useState([]);
  const [enrichedAccountsData, setEnrichedAccountsData] = useState([]);
  const [balanceSumData, setBalanceSumData] = useState([]);
  const [transactionsData, setTransactionsData] = useState([]);
  const [risk, setRisk] = useState(0);

  useEffect(() => {
    BlockmateAPIService.balanceList(getConfig("blockmate_api"), token, getConfig("currency")).then(data => {
      setAccountsData(data.accounts);
      setBalanceSumData(data.balance_sum);
    }).catch((reason) => {
      handleApiError("getting balances", reason);
    });
  }, [token, getConfig]);

  useEffect(() => {
    BlockmateAPIService.transactionList(getConfig("blockmate_api"), token, getConfig("currency")).then(data => {
      const transactions = data?.transactions;
      if (transactions !== undefined) {
        setTransactionsData(transactions);
      }
    }).catch((reason) => {
      handleApiError("getting transactions list", reason);
    });

  }, [token, getConfig]);

  useEffect(() => {
    accountsData.forEach(account => {
      // aggregate balance in USD
      let usd_sum = 0;
      account.balance.forEach(balance => {
        if (balance.converted_value) {
          usd_sum += balance.converted_value;
        }
      });
      account.account_balance_sum = usd_sum;
    });

    // collect risk info
    Promise.all(accountsData.map(async (account) => {
      account.risk = await BlockmateAPIService.addressRisk(getConfig("blockmate_api"), token, account.wallet ?? account.description, account.name)
        .then(a => a.risk)
        .catch((reason) => {
          handleApiError("getting risk", reason);
        });
      return account;
    })).then((data => {
      let max_risk = 0;
      data.forEach(account => {
        if (account.risk > max_risk) {
          max_risk = account.risk;
        }
      });

      setRisk(max_risk);
      setEnrichedAccountsData(data);
    }));
  }, [accountsData, getConfig, token]);

  return (
    <>
      <LinkModal
        url={getConfig("link_url")}
        jwt={linkToken}
        cleanupActions={{
          success: () => window.location.reload() // Do not eta-reduce this
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Total balanceSum={balanceSumData} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Risk risk={risk} />
        </Grid>
      </Grid>
      <br/>
      <Accounts enrichedAccountsData={enrichedAccountsData} />
      <br />
      <Balance balanceSum={balanceSumData} />
      <br />
      <Transactions transactions={transactionsData} />
    </>
  );
}

export default Dashboard;