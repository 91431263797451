import {createContext, useCallback, useEffect, useState} from "react";
import TemporaryUserService from "../../services/TemporaryUserService";
import LinkService from "../../services/LinkService";
import {useConfig} from "../../components/config/config";
import handleApiError from "../../services/ErrorHandleUtil";
import BlockmateAPIService from "../../services/BlockmateAPIService";
import {accountsCompareFn} from "../../utils/george/accounts";

export const UserContext = createContext(null);

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const UserContextProvider = ({children}) => {
  const {getConfig} = useConfig();
  const [userID, setUserID] = useState(localStorage.getItem("user_id"));
  const [cluid, setCluid] = useState(localStorage.getItem("cluid"));
  const [token, setToken] = useState(null);
  const [linkToken, setLinkToken] = useState(null);
  const [providers, setProviders] = useState([]);
  const [accountsData, setAccountsData] = useState([]);
  const [balanceSumData, setBalanceSumData] = useState([]);

  useEffect(() => {
    if (!getConfig("use_cluid") && userID == null) {
      TemporaryUserService.getTemporaryUser().then((uuid) => {
        localStorage.setItem("user_id", uuid);
        setUserID(uuid);
      }).catch((reason) => {
        handleApiError("getting user", reason);
      });
    }
  }, [userID, setUserID]);

  useEffect(() => {
    const decodedJwt = parseJwt(token);
    if(getConfig("use_cluid")) {
      if (cluid != null && cluid !== "" && (token == null || decodedJwt == null || decodedJwt.exp * 1000 < Date.now())) {
        TemporaryUserService.getJWTbyCLUID(cluid).then((newToken) => {
          setToken(newToken);
        }).catch((reason) => {
          handleApiError("getting jwt", reason);
        });
      }
    } else {
      if (userID != null && (token == null || decodedJwt == null || decodedJwt.exp * 1000 < Date.now())) {
        TemporaryUserService.getJWT(userID).then((newToken) => {
          setToken(newToken);
        }).catch((reason) => {
          handleApiError("getting jwt", reason);
        });
      }
    }
  }, [userID, token, setToken, cluid]);

  useEffect(() => {
    const decodedJwt = parseJwt(token);
    const decodedLinkJwt = parseJwt(linkToken);
    if (token != null && decodedJwt != null && decodedJwt.exp * 1000 >= Date.now() && (linkToken == null || decodedLinkJwt == null || decodedLinkJwt.exp * 1000 < Date.now())) {
      LinkService.getLinkToken(getConfig("link_url"), token).then((link_token) => {
        setLinkToken(link_token);
      }).catch((reason) => {
        handleApiError("getting link token", reason);
      });
    }
  }, [linkToken, token, setLinkToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      const decodedJwt = parseJwt(token);
      const decodedLinkJwt = parseJwt(linkToken);

      if (token != null && (decodedJwt == null || decodedJwt.exp * 1000 < Date.now())) {
        setToken(null);
      }
      if (linkToken != null && (decodedLinkJwt == null || decodedLinkJwt.exp * 1000 < Date.now())) {
        setLinkToken(null);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [token, linkToken, setToken, setLinkToken]);

  useEffect(() => {
    if (linkToken && providers.length === 0) {
      LinkService.getProviders(getConfig("link_url"), linkToken)
        .then(providersResponse => setProviders(providersResponse))
        .catch(reason => handleApiError("getting providers", reason));
    }
  }, [linkToken]);

  useEffect(() => {
    if (token && getConfig) {
      BlockmateAPIService.balanceList(getConfig("blockmate_api"), token, getConfig("currency")).then(data => {
        const sortedAccounts = data?.accounts ?? [];
        sortedAccounts.sort(accountsCompareFn);
        setAccountsData(sortedAccounts);
        setBalanceSumData(data.balance_sum);
      }).catch((reason) => {
        handleApiError("getting balances", reason);
      });
    }
  }, [token, getConfig]);

  const setCluidExported = useCallback((x) => {
    localStorage.setItem("cluid", x);
    setCluid(x);
    setToken(null);
    setLinkToken(null);
  }, [setCluid]);

  const data = {
    userID,
    cluid,
    token,
    linkToken,
    providers,
    accountsData,
    balanceSumData,
    setCluid: setCluidExported,
  };

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};

export default UserContextProvider;