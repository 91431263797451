const appRoutes = {
  dashboard: "/",
  debug: "/debug",
  cluid: "/cluid",
  wallet: {
    list: "/list"
  },
  auth: {
    login: "/login"
  },
  savings: "savings",
  george: {
    dashboard: "/george/dashboard"
  },
  deposits: {
    demo: "/deposits-demo",
  },
};

export default appRoutes;
