import HoverableComponent from "./HoverableComponent";
import CopyComponent from "./CopyComponent";
import Chip from "@mui/material/Chip";
import CategoryIcon from "@mui/icons-material/Category";
import React from "react";

const TransactionMovement = ({ data }) => {
  return (
    <table>
      <tbody>
        {data !== [] && data.filter(r => r.fee !== true).map((input_row, index) =>
          <tr key={index} style={{ marginBottom: "5px" }}>
            <td style={{ width: "30px", color: "#7346F3", textDecoration: "underline" }}>
              <HoverableComponent text={input_row.address} length="10" />
            </td>
            <td>
              <CopyComponent text={input_row.address} />
            </td>
            <td>
              {input_row.category !== "" &&
              <Chip icon={<CategoryIcon />} color="warning" size="small" variant="outlined"
                label={input_row.category} />
              }
            </td>
            <td>
              {input_row.owned === true &&
              <Chip size="small" variant="filled" label="Owned" />
              }
            </td>
            <td>
              {input_row.name !== "" &&
              <Chip size="small" variant="filled" label={input_row.name} />
              }
            </td>
            <td>
              <span style={{ color: "#000" }}>
                {input_row.value} {input_row.currency_symbol}
              </span>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TransactionMovement;