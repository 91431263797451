import styles from "./asset-graph.module.scss";
import { ReactComponent as InfoIcon } from "../../../assets/icons/george/info.svg";
import BalanceDelta from "../common/BalanceDelta";
import {Line, LineChart, XAxis, ResponsiveContainer, Tooltip, YAxis} from "recharts";
import {formatCurrencyValue} from "../../../utils/common";
import {useContext, useEffect, useMemo, useState} from "react";
import {UserContext} from "../../../contexts/UserContext/UserContext";
import BlockmateAPIService from "../../../services/BlockmateAPIService";
import {useConfig} from "../../config/config";

const AssetGraph = ({ asset, currency = "USD" }) => {
  const GRAPH_MONTHS = 6;
  const DATA_COLORS = [
    "#7346F3",
    "#6DFF96",
  ];

  const { getConfig } = useConfig();
  const { token } = useContext(UserContext);

  const exchangeRatesDates = useMemo(() => {
    const dates = [];
    const today = new Date();
    for (let i = GRAPH_MONTHS - 1; i >= 0; i--) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const shiftedDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
      dates.push(shiftedDate.toISOString().split("T")[0]);
    }
    return dates;
  }, []);

  const [exchangeRates, setExchangeRates] = useState([]);

  const chartData = useMemo(() => {
    const newData = exchangeRates.map((rate, index) => ({
      date: exchangeRatesDates[index],
      rate,
    }));
    return newData.map(data => {
      const shortMonthName = new Date(data.date).toLocaleString("default", { month: "short" });
      return {
        ...data,
        dateLabel: shortMonthName
      };
    });
  }, [exchangeRates]);

  useEffect(() => {
    if (token) {
      BlockmateAPIService.exchangeRatesHistory(
        getConfig("blockmate_api"), token, `${asset.currency_symbol}/${currency}`, exchangeRatesDates.join(",")
      )
        .then(rates => setExchangeRates(
          (rates ?? [])
            .filter(rateInfo => rateInfo.rate !== null)
            .map(rateInfo => rateInfo.rate))
        );
    }
  }, [token, asset]);

  const CustomTooltip = ({ active, payload, currency }) => {
    if (active && (payload ?? []).length > 0) {
      return (
        <div className={styles.customTooltip}>
          <p className={styles.title}>{formatCurrencyValue(payload[0].payload.rate, currency, 2)}</p>
          <p className={styles.subtitle}>{payload[0].payload.date}</p>
        </div>
      );
    }
    return null;
  };

  return <div className={styles.container}>
    <div className={styles.headingRowContainer}>
      <div className={styles.headingText}>
        My {asset.currency_name} digital assets
      </div>
      <div className={styles.headingIcon}>
        <InfoIcon />
      </div>
    </div>
    <div className={styles.deltasRowContainer}>
      <BalanceDelta
        absoluteChange={-82.19}
        percentChange={-4.8}
        currency={currency}
        timeframe="30d"
        centering="left"
      />
      <BalanceDelta
        absoluteChange={208.9}
        percentChange={12.2}
        currency={currency}
        timeframe="90d"
        centering="right"
      />
    </div>
    <div className={styles.chart}>
      <ResponsiveContainer width="100%" height={270}>
        <LineChart
          data={chartData}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor={DATA_COLORS[0]}/>
              <stop offset="100%" stopColor={DATA_COLORS[1]}/>
            </linearGradient>
          </defs>
          <XAxis
            dataKey="dateLabel"
            tick={{width: 10}}
            tickMargin="9"
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            type="number"
            domain={["dataMin", "dataMax"]}
            hide={true}
          />
          <Tooltip content={<CustomTooltip active={true} currency={currency}/>} cursor={false}/>
          <Line
            key="rate"
            type="monotone"
            dataKey="rate"
            stroke="url(#colorGradient)"
            dot={false}
            strokeWidth={4}
            activeDot={{
              strokeWidth: 7,
              className: styles.chartActiveDot,
              fill: DATA_COLORS[0],
              r: 10
            }}
          />;
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>;
};

export default AssetGraph;
