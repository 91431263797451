import styles from "./george.module.scss";
import {useContext, useState} from "react";
import {clsx} from "clsx";
import GeorgePortfolio from "../../components/george/portfolio/GeorgePortfolio";
import GeorgeAccounts from "../../components/george/accounts/GeorgeAccounts";
import GeorgeBalanceTile from "../../components/george/tile/GeorgeBalanceTile";
import { ReactComponent as BlockmateLogoFull } from "../../assets/icons/george/blockmate-full-logo.svg";
import { ReactComponent as BackArrowIcon } from "../../assets/icons/george/back-arrow.svg";
import {LinkModal} from "blockmate-react-link";
import {UserContext} from "../../contexts/UserContext/UserContext";
import {useConfig} from "../../components/config/config";
import {valueExists} from "../../utils/common";

const GeorgeDashboard = () => {
  const [componentKey, setComponentKey] = useState(0);
  const [backAction, setBackAction] = useState();

  const menuEntries = [
    {
      label: "Accounts",
      component: <GeorgeAccounts key={`accounts-${componentKey}`} setBackAction={setBackAction} />,
    },
    {
      label: "Portfolio",
      component: <GeorgePortfolio key={`portfolio-${componentKey}`} />
    },
  ];

  const handleSelectMenuEntry = (entry) => {
    setComponentKey(prevKey => prevKey + 1);
    setSelectedMenuEntry(entry);
    if (valueExists(backAction)) {
      backAction();
    }
    setBackAction(undefined);
  };

  const handleBackArrowClick = () => {
    backAction();
    setBackAction(undefined);
  };

  const { getConfig } = useConfig();
  const { linkToken } = useContext(UserContext);
  const [selectedMenuEntry, setSelectedMenuEntry] = useState(menuEntries[0]);

  return <div className={styles.outerContainer}>
    <LinkModal
      url={getConfig("link_url")}
      jwt={linkToken}
      cleanupActions={{
        success: () => window.location.reload() // Do not eta-reduce this
      }}
    />
    <div className={styles.topBackground}></div>
    <div className={styles.innerContainer}>
      <div className={styles.topLogoContainer}>
        {valueExists(backAction) && <div className={styles.backArrowContainer} onClick={handleBackArrowClick}>
          {/*<div className={styles.backArrowContainer} onClick={backAction}>*/}
          <BackArrowIcon />
        </div>}
        <div className={styles.textWithLogoConainer}>
          <div className={styles.textWithLogo}>
            Powered by <BlockmateLogoFull className={styles.logo} />
          </div>
        </div>
      </div>
      <div className={styles.balanceTile}>
        <GeorgeBalanceTile
          balance={4827.88}
          deltaPercent={4.8}
          deltaAbsolute={157.45}
        />
      </div>
      <div className={styles.menu}>
        {menuEntries.map(entry => (
          <div
            className={clsx(styles.menuItem, selectedMenuEntry.label === entry.label && styles.active)}
            onClick={() => handleSelectMenuEntry(entry)}
            key={entry.label}
          >
            {entry.label}
          </div>
        ))}
      </div>
      <div className={styles.contentContainer}>
        {selectedMenuEntry.component}
      </div>
    </div>
  </div>;
};

export default GeorgeDashboard;
