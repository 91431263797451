import styles from "./circular-balance-display.module.scss";
import * as d3 from "d3";
import {useMemo} from "react";
import {formatCurrencyValue} from "../../../utils/common";
import {ARC_COLORS, HIDDEN_COLOR} from "../../../utils/george/colors-config";
import BalanceDelta from "../common/BalanceDelta";
import {clsx} from "clsx";

const CircularBalanceDisplay = ({ assets = [], percentChange, absoluteChange, selectedAssetIndex = undefined, currency = "USD" }) => {
  const ARCS_GAP = 0.0005 * Math.PI;
  const currentAbsoluteChange = useMemo(() => {
    if (selectedAssetIndex === undefined) {
      return absoluteChange;
    }
    return assets[selectedAssetIndex].absolute_change;
  }, [absoluteChange, selectedAssetIndex]);
  const currentPercentChange = useMemo(() => {
    if (selectedAssetIndex === undefined) {
      return percentChange;
    }
    return assets[selectedAssetIndex].percent_change;
  }, [percentChange, selectedAssetIndex]);
  const totalConvertedBalance = useMemo(
    () => assets.reduce((acc, asset) => acc + asset.converted_value ?? 0, 0),
    [assets]
  );
  const currentConvertedBalance = useMemo(() => {
    if (selectedAssetIndex === undefined) {
      return totalConvertedBalance;
    }
    return assets[selectedAssetIndex]?.converted_value ?? 0;
  }, [totalConvertedBalance, selectedAssetIndex]);
  const ratios = useMemo(
    () => {
      return assets.map(asset => asset.converted_value / totalConvertedBalance);
    },
    [assets]
  );
  const arcSegments = useMemo(
    () => {
      if (ratios.length === 1) {
        return [{ from: 0, to: 2 * Math.PI }];
      }
      const prefixSums = [0];
      ratios.forEach((ratio, index) => {
        prefixSums.push(prefixSums[index] + ratio);
      });
      const segments = prefixSums.map((prefixSum, index) => {
        return {
          from: 2 * Math.PI * prefixSum,
          to: 2 * Math.PI * (prefixSums[(index + 1) % prefixSums.length] - ARCS_GAP)
        };
      });
      segments.pop();
      return segments;
    },
    [ratios]
  );

  const createArc = (startAngle, endAngle, color, wider = false, withShadow = false) => {
    const wideGirth = 8;
    const narrowGirth = 6;
    const girth = wider ? wideGirth : narrowGirth;
    const radius = 100 - wideGirth / 2 - 2;
    const arc = d3.arc()
      .cornerRadius(2)
      .innerRadius(radius - girth / 2)
      .outerRadius(radius + girth / 2)
      .startAngle(startAngle)
      .endAngle(endAngle);

    return (
      <path d={arc()} fill={color} key={String(startAngle)} className={clsx(withShadow && styles.arcWithShadow)} />
    );
  };

  return (
    <div className={styles.circleContainer}>
      <svg className={styles.graphSvg} viewBox="0 0 220 220">
        <g transform="translate(110, 110)">
          {selectedAssetIndex === undefined && arcSegments.map((segment, index) =>
            createArc(segment.from, segment.to, ARC_COLORS[index % ARC_COLORS.length])
          )}
          {selectedAssetIndex !== undefined && arcSegments.map((segment, index) => {
            if (index === selectedAssetIndex) {
              return createArc(segment.from, segment.to, ARC_COLORS[index % ARC_COLORS.length], true, true);
            }
            return createArc(segment.from, segment.to, HIDDEN_COLOR);
          })}
        </g>
      </svg>
      <div className={styles.balanceDisplay}>
        <div className={styles.graphTextArea}>
          <div className={styles.graphTextContainer}>
            <div className={styles.heading}>
              {assets.length} Digital Assets
            </div>
            <div className={styles.balance}>
              {formatCurrencyValue(currentConvertedBalance, currency, 2)}
            </div>
            <BalanceDelta
              absoluteChange={currentAbsoluteChange}
              percentChange={currentPercentChange}
              currency={currency}
              timeframe="24h"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircularBalanceDisplay;
