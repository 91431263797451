import styles from "./tile.module.scss";
import { ReactComponent as BlockmateLogoInCircle } from "assets/icons/george/blockmate-logo-in-circle.svg";
import { formatCurrencyValue } from "../../../utils/common";
import BalanceDelta from "../common/BalanceDelta";

const GeorgeBalanceTile = ({ balance, deltaPercent, deltaAbsolute, currency = "USD" }) => {
  return <div className={styles.container}>
    <div className={styles.contentContainer}>
      <div className={styles.heading}>Connected Digital Assets</div>
      <div className={styles.balanceContainer}>
        <div className={styles.balance}>
          {formatCurrencyValue(balance, currency, 2)}
        </div>
        <BalanceDelta
          absoluteChange={deltaAbsolute}
          percentChange={deltaPercent}
          currency={currency}
          timeframe="24h"
          oneline
        />
      </div>
    </div>
    <div className={styles.logoContainer}>
      <div className={styles.logo}>
        <BlockmateLogoInCircle />
      </div>
    </div>
  </div>;
};

export default GeorgeBalanceTile;
