import "styles/components/components.scss";

const MainButton = ({ onClick, type, variant="", label, icon, disabled, extraClasses }) => {
  const disabledClass = disabled ? "disabled" : "";

  return (
    <button
      disabled={disabled}
      className={`main-button ${variant} ${extraClasses} ${disabledClass}`}
      onClick={onClick}
      type={type}
    >
      <>
        {icon && <span className='icon'>{icon}</span>}
        {label}
      </>
    </button>
  );
};

export default MainButton;
