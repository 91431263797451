import "styles/pages/dashboard/dashboard.css";
import React, { useContext } from "react";
import { UserContext } from "contexts/UserContext/UserContext";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

function Debug() {
  const { userID, token, linkToken } = useContext(UserContext);

  return (
    <>
      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <div className="container-header">
          <div className="container-header-icon-div">
            <div className="container-header-icon-div-body">
              <img className="container-icon" alt="" src="../images/accounts.svg" />
            </div>
          </div>
          <b className="container-title"><Typography variant="h5" key="wallets_h">Debug info</Typography></b>
        </div>

        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>Name</TableCell>
              <TableCell align="left" style={{
                background: "#fafafa",
                textColor: "white",
                letterSpacing: "0.14em",
                fontWeight: "bold",
                fontSize: "12px"
              }}>Info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">UserID</TableCell>
              <TableCell align="left">{userID}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">JWT</TableCell>
              <TableCell align="left">{token}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">LinkJWT</TableCell>
              <TableCell align="left">{linkToken}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <br />
    </>
  );
}

export default Debug;
