import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import appRoutes from "routes/routes";
import { Link } from "react-router-dom";
import LinkService from "../../services/LinkService";
import { useConfig } from "../config/config";
import handleApiError from "../../services/ErrorHandleUtil";
import ProviderInfo from "./ProviderInfo";
import { formatCurrencyValue } from "../../utils/common";


function AccountsAmlDemo({ enrichedAccountsData, jwt }) {
  const [linkToken, setLinkToken] = useState();
  const [providers, setProviders] = useState([]);
  const { getConfig } = useConfig();

  const headerStyle = {
    background: "#fafafa",
    textColor: "white",
    letterSpacing: "0.14em",
    fontWeight: "bold",
    fontSize: "12px"
  };

  const dataStyle = {
    fontSize: "16px",
    fontWeight: 500,
    color: "#888888",
    paddingTop: "24px",
    paddingBottom: "24px",
  };

  useEffect(() => {
    LinkService.getLinkToken(getConfig("link_url"), jwt)
      .then(newLinkTokenResponse => setLinkToken(newLinkTokenResponse))
      .catch(reason => handleApiError("getting link token", reason));
  }, []);

  useEffect(() => {
    if (linkToken && providers.length === 0) {
      LinkService.getProviders(getConfig("link_url"), linkToken)
        .then(providersResponse => setProviders(providersResponse))
        .catch(reason => handleApiError("getting providers", reason));
    }
  }, [linkToken]);

  const formatType = (type) => {
    const withSpaces = type.split("_").join(" ");
    return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);
  };

  return (
    <>
      {enrichedAccountsData.length === 0 && (
        <Alert severity="error">
          <AlertTitle>No data</AlertTitle>
          <div><Link to={appRoutes.wallet.list}>Connect your wallet</Link></div>
        </Alert>
      )}

      <br />

      <TableContainer
        component={Paper}
        key="account_table_container"
        sx={{ borderRadius: "10px" }}
      >
        <div className="container-header header-with-button">
          <div className="inline-group">
            <div className="container-header-icon-div">
              <div className="container-header-icon-div-body">
                <img className="container-icon" alt="" src="../images/connect.svg"/>
              </div>
            </div>
            <b className="container-title"><Typography variant="h5" key="account_h">Connected crypto accounts</Typography></b>
          </div>
        </div>

        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
          key="account_table"
        >
          <TableHead>
            <TableRow variant="head" style={{ background: "#fafafa" }}>
              <TableCell align="left" style={headerStyle}>ACCOUNT DESCRIPTION</TableCell>
              <TableCell align="left" style={headerStyle}>BALANCE</TableCell>
              <TableCell align="left" style={headerStyle}>PROVIDER</TableCell>
              <TableCell align="left" style={headerStyle}>TYPE</TableCell>
              <TableCell align="left" style={headerStyle}>VERIFIED</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {enrichedAccountsData.length && enrichedAccountsData.map((row, index) => (
              <TableRow
                key={"accounts_row_" + index}
                sx={{"&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={dataStyle}>{row.description}</TableCell>
                <TableCell align="left" style={dataStyle}>
                  {row.account_balance_sum
                    ? formatCurrencyValue(
                      Math.round(row.account_balance_sum * 100) / 100, row.converted_currency
                    )
                    : "-"
                  }
                </TableCell>
                <TableCell align="left" style={dataStyle}>
                  <ProviderInfo providerName={row.name} allProviders={providers} />
                </TableCell>
                <TableCell align="left" style={dataStyle}>{formatType(row.type)}</TableCell>
                <TableCell align="left" style={dataStyle}>
                  {row.verified && <CheckCircleIcon style={{color: "#6dff96"}} />}
                  {!row.verified && <CancelIcon style={{color: "#ef032d"}} />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default AccountsAmlDemo;
